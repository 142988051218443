import copy from "copy-to-clipboard"
import React, { useState } from "react"
import Button from "../../components/elements/Button"
import { H1, H2 } from "../../components/elements/Headings"
import Input from "../../components/elements/Input"
import {
  ContentContainer,
  PageTitleContainer,
} from "../../components/layout/Containers"
import Layout from "../../components/layout/Layout"
import SEO from "../../components/seo"
import NotificationUtils from "../../utils/notification"

function TimestampToDatePage() {
  const [timestamp, setTimestamp] = useState("")
  const [date, setDate] = useState<Date | null>(null)

  function convert() {
    if (timestamp != null && timestamp != "") setDate(new Date())
  }

  function reset() {
    setTimestamp("")
    setDate(null)
  }

  const copyToClipboard = () => {
    copy(getStringDate())
    NotificationUtils.default({
      title: "Copied!",
      description: "Date copied to your clipboard",
    })
  }

  function getStringDate(): string {
    return date.toLocaleDateString()
    // return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
  }

  return (
    <>
      <SEO title="Timestamp to Date" />
      <Layout>
        <PageTitleContainer>
          <H1>Timestamp to Date</H1>
          <H2>Paste your timestamp here and press the button!</H2>
        </PageTitleContainer>
        <ContentContainer className="flex justify-between">
          <Input
            className="w-64"
            placeholder="Timestamp to convert..."
            onChange={setTimestamp}
            type="number"
            value={timestamp}
          />
          <div className="flex">
            <Button click={convert} primary>
              Convert
            </Button>
            <Button
              click={() => {
                setTimestamp("" + new Date().getTime())
                convert()
              }}
              secondary
            >
              Current Timestamp
            </Button>
            <Button click={reset} accent>
              Reset
            </Button>
          </div>
        </ContentContainer>
        {date && (
          <ContentContainer className="mt-8 flex">
            <div className="self-center">
              The date is <strong>{getStringDate()}</strong>
            </div>
            <div>
              <Button accent click={copyToClipboard}>
                Copy to clipboard!
              </Button>
            </div>
          </ContentContainer>
        )}
      </Layout>
    </>
  )
}

export default TimestampToDatePage
