import React from "react"

type InputProps = {
  className?: string
  placeholder?: string
  onChange?: any
  value?: any
  type?: "text" | "number" | "date"
}

const Input = ({
  placeholder,
  className,
  onChange,
  value,
  type = "text",
}: InputProps) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      className={`shadow-lg appearance-none border border-light rounded py-2 px-3 text-gray-700 leading-tight ${className}`}
      onChange={(event: any) =>
        onChange ? onChange(event.target.value) : null
      }
    />
  )
}

export default Input
