import React from "react"
import PropTypes from "prop-types"
import Icon from "./Icon"

type ButtonProps = {
  click: any
  children: any
  className?: string
  icon?: "hash"
  primary?: boolean
  secondary?: boolean
  accent?: boolean
}

export default function Button({
  click,
  children,
  className,
  icon,
  primary,
  secondary,
  accent,
}: ButtonProps) {
  let classes: string = "".concat(className ? className : "").concat(" ")

  if (primary) classes = classes.concat("bg-primary text-white")

  if (secondary) classes = classes.concat("bg-secondary text-white")

  if (accent) classes = classes.concat("bg-accent text-white")

  return (
    <button
      className={`flex flex-row shadow hover:shadow-lg transition-shadow duration-150 text-white font-bold py-2 px-4 mx-1 md:my-1 text-center rounded ${classes}`}
      onClick={click}
    >
      {icon && <Icon icon={icon} />}
      {children}
    </button>
  )
}
