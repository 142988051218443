import { store } from "react-notifications-component"
import "animate.css"

const NotificationUtils = {
  default: ({ title, description }) => {
    return store.addNotification({
      title: title,
      message: description,
      type: "default",
      container: "top-right",
      insert: "right",
      // touchSlidingExit: {
      //   swipe: {
      //     duration: 400,
      //     timingFunction: "ease-out",
      //     delay: 0,
      //   },
      //   fade: {
      //     duration: 400,
      //     timingFunction: "ease-out",
      //     delay: 0,
      //   },
      // },
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 2000,
        onScreen: true,
      },
    })
  },
  error: ({ title, description }) => {
    return store.addNotification({
      title: title,
      message: description,
      type: "error",
      container: "top-center",
      insert: "top",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 2000,
        onScreen: true,
      },
    })
  },
}

export default NotificationUtils
